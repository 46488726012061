<template>
  <el-main>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="
        this.$route.query.type == 'edit'
          ? $t('cip.plat.sys.dept.title.editHeadTitle')
          : $t('cip.plat.sys.dept.title.addHeadTitle')
      "
      @head-save="headSave('save')"
      @head-save-back="headSave('back')"
      @head-cancel="headCancel"
    >
    </head-layout>
    <form-layout ref="formLayout" :column="column" :dataForm.sync="dataObj">
      <!-- <template slot="areaId">
          <el-cascader
            placeholder="请选择机构位置"

            ref="prjAreaCascader"
            filterable
            style="width: 100%"
            v-model="dataObj.areaId"
            :options="areaDic"
            @change="handleChangeArea"
          ></el-cascader>
        </template> -->
    </form-layout>
  </el-main>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import website from "@/config/website";
import { add, getDept, getDeptTree } from "@/api/system/dept";
import { getLazyTree, getAreaData1 } from "@/api/base/region";
import { mapGetters } from "vuex";
import { validatAlphabets } from "../../util/validate";

export default {
  components: { FormLayout, HeadLayout },
  computed: {
    ...mapGetters(["language"]),
    column(){
      let that = this;
      return [
        {
          label: that.$t("cip.plat.sys.dept.field.deptName"),
          prop: "deptName",
          search: true,
          isGlobal: false, //是否支持国际化
          rules: [
            {
              required: true,
              validator: that.validateDeptName,
              trigger: ["blur", "change"],
            },
          ],
        },
        {
          label: that.$t("cip.plat.sys.dept.field.tenantId"),
          prop: "tenantId",
          type: "tree",
          dicUrl: "/api/sinoma-system/tenant/select",
          addDisplay: false,
          editDisplay: false,
          viewDisplay: website.tenantMode,
          props: {
            label: "tenantName",
            value: "tenantId",
          },
          hide: !website.tenantMode,
          search: website.tenantMode,
          rules: [
            {
              required: true,
              message:
                that.$t("cip.cmn.rule.inputWarning") +
                that.$t("cip.plat.sys.dept.field.tenantId"),
              trigger: "click",
            },
          ],
        },
        {
          label: that.$t("cip.plat.sys.dept.field.fullName"),
          prop: "fullName",
          search: true,
          isGlobal: false, //是否支持国际化
          rules: [
            {
              required: true,
              validator: that.validateFullName,
              trigger: ["blur", "change"],
            },
          ],
        },
        {
          label: that.$t("cip.plat.sys.dept.field.parentId"),
          prop: "parentId",
          dicData: that.deptTreeData,
          type: "tree",
          hide: true,
          addDisabled: false,
          disabled: false,
          props: {
            label: "title",
            value: "value",
          },
          rules: [
            {
              required: true,
              message:
                that.$t("cip.cmn.rule.inputWarning") +
                that.$t("cip.plat.sys.dept.field.parentId"),
              trigger: "click",
            },
          ],
        },
        {
          label: that.$t("cip.plat.sys.dept.field.deptCode"),
          prop: "deptCode",
          span: 8,
          rules: [
            {
              required: true,
              message:
                that.$t("cip.cmn.rule.selectWarning") +
                that.$t("cip.plat.sys.dept.field.deptCode"),
              trigger: "blur",
            },
          ],
        },
        // {
        //   label: that.$t("cip.plat.sys.dept.field.isEnable"),
        //   prop: "isEnable",
        //   type: "switch",
        //   value: 1,
        //   dataType: "number",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        //   dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
        // },
        {
          label: that.$t("cip.plat.sys.dept.field.sort"),
          prop: "sort",
          type: "number",
          minRows: 0,
          maxRows: 1000000000,
          align: "right",
          width: 80,
          rules: [
            {
              required: true,
              message:
                that.$t(`cip.cmn.rule.inputWarning`) +
                that.$t(`cip.plat.sys.dept.field.sort`),
              trigger: "blur",
            },
          ],
        },
        {
          label: "境内外",
          prop: "companyLocation",
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=domestic_overseas",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dataType: "number",
          rules: [
            {
              required: false,
              message:
                that.$t("cip.cmn.rule.selectWarning") +
                that.$t("cip.plat.sys.dept.field.companyLocation"),
              trigger: "blur",
            },
          ],
        },
        {
          label: "机构位置",
          prop: "areaId",
          type: "cascader",
          dicData: that.areaDic,
          rules: [
            {
              required: false,
              message: "请选择机构位置",
              trigger: "change",
            },
          ],
        },
        {
          label: "所属板块",
          prop: "deptSegment",
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=biz_sector",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dataType: "number",
          rules: [
            {
              required: false,
              message: that.$t("cip.cmn.rule.selectWarning") + "所属板块",
              trigger: "blur",
            },
          ],
        },
        // {
        //   label: "是否纳入安全管理",
        //   prop: "hseFlag",
        //   type: "switch",
        //   // dataType: "number",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        //   dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
        //   labelWidth: "120px",
        //   value: "",
        //   display: that.dataObj.deptCategory == '2',
        // },
        {
          label: that.$t("cip.plat.sys.dept.field.remark"),
          span: 24,
          maxlength: "500",
          showWordLimit: true,
          type: "textarea",
          prop: "remark",
          rules: [
            {
              required: false,
              message:
                that.$t("cip.cmn.rule.inputWarning") +
                that.$t("cip.plat.sys.dept.field.remark"),
              trigger: "blur",
            },
          ],
          hide: true,
        },
      ]
    },
  },
  data() {
    return {
      headBtnOptions: [
        {
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-save-back",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        },
      ],
      dataObj: {},
      parentId: 0,
      areaDic: [],
      deptTreeData: []
    };
  },
  watch: {
    "dataObj.parentId": {
      handler(val) {
        console.log(val, "val");
        if (this.$route.query.type == "add") {
          if (val == undefined || val == "") {
            this.dataObj.deptSegment = "";
          } else if (val != "" || val != undefined) {
            getDept(val).then((res) => {
              const { data } = res;
              this.dataObj.deptSegment = data.data.deptSegment;
            });
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getAreaData();
    if (this.$route.query.tenantStatus != "true") {
      this.findObject(this.column, "tenantId").display = false;
      this.dataObj.tenantId = "000000";
    }
    if (this.$route.query.type == "edit") {
      getDept(this.$route.query.id).then((res) => {
        const { data } = res;
        this.dataObj = data.data;
      });
    } else if (this.$route.query.type == "addChild") {
      this.parentId = this.$route.query.id;
      getDept(this.parentId).then((res) => {
        const { data } = res;
        this.dataObj.deptSegment = data.data.deptSegment;
      });
      const column = this.findObject(this.column, "parentId");
      column.disabled = true;
      column.value = this.$route.query.id;
      column.addDisabled = true;
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    validateFullName  (rule, value, callback)  {
      if (value != "") {
        let val = value; // JSON.parse(value);
        // let language = this.language;
        let val2 = val; //val[language];
        // if (
        //   val2.substring(0, 1) == " " ||
        //   val2.substring(val2.length - 1) == " "
        // ) {
        //   callback(new Error(this.$t("cip.cmn.rule.deleteWarning")));
        // }
        if (val2.length > 60) {
          callback(new Error(this.$t("cip.cmn.rule.nameLength4Warning")));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$t("cip.cmn.rule.noEmptyWarning")));
      }
    },
    validateDeptName  (rule, value, callback)  {
      if (value != "") {
        let val = value;// JSON.parse(value);
        // let language = this.language;
        let val2 = val; // val[language];
        // if (
        //   val2.substring(0, 1) == " " ||
        //   val2.substring(val2.length - 1) == " "
        // ) {
        //   callback(new Error(this.$t("cip.cmn.rule.deleteWarning")));
        // }
        if (val2.length > 60) {
          callback(new Error(this.$t("cip.cmn.rule.nameLength4Warning")));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$t("cip.cmn.rule.noEmptyWarning")));
      }
    },
    headSave(type) {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          if (Array.isArray(this.dataObj.areaId)) {
            this.dataObj.areaId = this.dataObj.areaId.join(",");
          } else if (
            this.dataObj.areaId == null ||
            this.dataObj.areaId.length == 0
          ) {
            this.dataObj.areaId = "";
          }
          // console.log(this.dataObj,'this.dataObj')
          // return
          // console.log(this.dataObj.areaId, "areaId");
          add(this.dataObj)
            .then(
              (res) => {
                if (type == "back") {
                  this.close();
                }
                this.dataObj = res.data.data;
                this.$message({
                  type: "success",
                  message: this.$t("cip.cmn.msg.success.operateSuccess"),
                });
                this.isShow = false;
                this.$refs.formLayout.$refs.form.allDisabled = false;
                //
              },
              (error) => {
                window.console.log(error);
                this.$refs.formLayout.$refs.form.allDisabled = false;
              }
            )
            .finally(() => {
              this.$loading().close();
            });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false;
        }
      });
    },
    initData() {
      getDeptTree('',2).then((res) => {
        this.deptTreeData = res.data.data;
      });
    },
    // 区域位置获取
    handleChangeArea(value) {
      const regionList =
        this.$refs["prjAreaCascader"].getCheckedNodes()[0].pathLabels;
      if (regionList && regionList.length != 0) {
        this.dataObj.areaName = regionList.join(",");
      }
      // const selectedLabel = this.getNodeLabel(this.areaDic, value);
      // console.log('selectedLabel--区域位置---',selectedLabel)
    },
    //   项目位置
    getAreaData(parentCode) {
      getAreaData1(parentCode, this.areaDic);
      const column = this.findObject(this.column, "areaId");
      column.dicData = this.areaDic;
    },
    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  },
};
</script>

<style>
</style>
